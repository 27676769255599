import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';
import * as dictionaryTypes from './dictionaryTypes';

const dependentProperties = {
  PROJECT: 'project',
  UNIT: 'unit',
  BUILDING: 'building',
  USAGES: 'usages',
  CLIENT_TYPE: 'clientType',
  SALES_CONTRACT_DATE: 'salesContractDate',
  ARCHIVED_USAGES: 'archivedUsages',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'client.personal_information',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'client.passport',
            prop: 'passport',
            rules: [rules.REQUIRED],
            size: 2,
          },
          {
            type: schemaItemTypes.COUNTRY,
            label: 'client.country',
            prop: 'country',
            rules: [rules.REQUIRED],
            dictionary: dictionaryTypes.COUNTRIES,
            size: 2,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'client.phone',
            prop: 'phone',
            rules: [rules.REQUIRED],
            size: 2,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'client.email',
            prop: 'email',
            rules: [rules.REQUIRED],
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'client.address',
    children: [
      {
        type: schemaItemTypes.CONTRACTS,
        size: 6,
        isUnit: false,
        payload: [
          {
            param: 'usageId',
            from: ['id'],
          },
        ],
      },
    ],
  },
];

export const schemaForShortTenants = [
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'client.address',
    children: [
      {
        type: schemaItemTypes.CONTRACTS,
        size: 6,
        isUnit: false,
        payload: [
          {
            param: 'usageId',
            from: ['id'],
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.EXPANSION_SECTION,
    label: 'client.history',
    children: [
      {
        type: schemaItemTypes.LIST,
        prop: dependentProperties.ARCHIVED_USAGES,
        uniqueKey: 'id',
        children: [
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                label: 'client.project',
                type: schemaItemTypes.PROJECT,
                prop: dependentProperties.PROJECT,
                rules: [rules.REQUIRED],
              },
              {
                type: schemaItemTypes.ENUM,
                viewType: 'radio',
                label: 'client.type',
                prop: dependentProperties.CLIENT_TYPE,
                dictionary: dictionaryTypes.CLIENT_TYPES,
              },
              {
                type: schemaItemTypes.BUILDING,
                label: 'client.building',
                prop: dependentProperties.BUILDING,
                rules: [rules.REQUIRED],
              },
              {
                type: schemaItemTypes.UNIT,
                label: 'client.unit',
                prop: dependentProperties.UNIT,
                rules: [rules.REQUIRED],
              },
              {
                type: schemaItemTypes.DATE,
                prop: 'rentalPeriod',
                label: 'client.rent',
              },
              {
                type: schemaItemTypes.COAST,
                prop: 'totalRentCost',
                label: 'client.cost',
              },
            ],
          },
        ],
      },
    ],
  },
];

export { schema as default };
