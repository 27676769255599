<template>
  <view-field v-if="currentValue" class="font-weight-medium" :label="label">
    <router-link :to="link">
      {{ currentValue }}
    </router-link>
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';
import { ROOMS_DETAILED, UNITS_DETAILED } from '@/constants/routes';

export default {
  name: 'SchemaViewFieldRoom',

  components: { ViewField },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      required: true,
    },
    unitId: {
      type: [Number, String],
      required: true,
    },
  },

  computed: {
    currentValue() {
      return this.value?.name || null;
    },

    link() {
      return {
        name: ROOMS_DETAILED,
        params: {
          id: this.value?.id,
          prevPageRoute: this.unitId
            ? {
                name: UNITS_DETAILED,
                params: { id: this.unitId },
              }
            : null,
        },
      };
    },
  },
};
</script>
