<template>
  <span
    v-if="status"
    class="clients-status ml-0 ml-md-4 mt-0 text-caption text-md-h5 d-flex align-center"
    :class="statusInfo.class"
  >
    <v-icon v-if="!media.isMobile" class="mr-2" :color="statusInfo.color">{{ statusInfo.icon }}</v-icon>
    {{ $t(statusInfo.text) }}
  </span>
</template>

<script>
import * as entityStatuses from '@/constants/entityStatuses';

const STATUS_CONFIG = {
  [entityStatuses.ARCHIVED]: {
    icon: 'mdi-account-cancel',
    color: 'grey',
    text: 'entity.archive',
    class: 'client-status--archive',
  },
  [entityStatuses.ACTIVE]: {
    icon: 'mdi-account-check',
    color: 'blue',
    text: 'entity.active',
    class: 'client-status--active',
  },
  [entityStatuses.UNCONFIRMED]: {
    icon: 'mdi-account-question',
    color: 'blue50',
    text: 'entity.unconfirmed',
    class: 'client-status--unconfirmed',
  },
};

export default {
  name: 'ClientStatus',
  inject: ['media'],
  props: {
    status: { type: String, default: '' },
  },
  computed: {
    statusInfo() {
      const statusInfo = STATUS_CONFIG[this.status] ?? STATUS_CONFIG[entityStatuses.ACTIVE];
      return statusInfo;
    },
  },
};
</script>

<style lang="scss">
.client-status {
  &--archive {
    color: $--grey-color-0;
  }

  &--unconfirmed {
    color: $--blue-color-35;
  }

  &--active {
    color: $--blue-color-0;
  }
}
</style>
