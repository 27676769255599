/* eslint-disable */
import client from '@/http/client';

// Utils
import { download, repackResponseFile } from '@/utils/common';

const BASE_URL = '/realty';

class RealtyService {
  async getProjects({ isArchived, limit, offset, search } = {}) {
    return client.get(`${BASE_URL}/projects/`, {
      params: {
        isArchived,
        limit,
        offset,
        search,
      },
    });
  }

  async getBuildings({ isArchived, projectId, limit, offset, search } = {}) {
    return (
      await client.get(`${BASE_URL}/buildings/`, {
        params: {
          isArchived,
          projectId,
          limit,
          offset,
          search,
        },
      })
    ).results;
  }

  async getUnits({ isArchived, buildingId, limit, offset, search } = {}) {
    return (
      await client.get(`${BASE_URL}/units/`, {
        params: {
          isArchived,
          buildingId,
          limit,
          offset,
          search,
        },
      })
    ).results;
  }

  async getRooms({ isArchived, parentUnitId, limit, offset, search } = {}) {
    return (
      await client.get(`${BASE_URL}/rooms/`, {
        params: {
          isArchived,
          parentUnitId,
          limit,
          offset,
          search,
        },
      })
    ).results;
  }

  getProjectById(id) {
    return client.get(`${BASE_URL}/projects/${id}/`);
  }

  getBuildingById(id) {
    return client.get(`${BASE_URL}/buildings/${id}/`);
  }

  getUnitById(id) {
    return client.get(`${BASE_URL}/units/${id}/`);
  }

  createProject(data, notificationSettings) {
    return notificationSettings 
      ? client.post(`${BASE_URL}/projects/`, { ...data, notificationSettings })
      : client.post(`${BASE_URL}/projects/`, data);
  }

  updateProject(data, notificationSettings, id) {
    return notificationSettings 
      ? client.patch(`${BASE_URL}/projects/${id}/`, { ...data, notificationSettings })
      : client.patch(`${BASE_URL}/projects/${id}/`, data);
  }

  getProjectImportStatus() {
    return client.get(`${BASE_URL}/projects/xlsx/status/`, {
      params: {
        timestamp: Date.now(),
      },
    });
  }

  createBuilding({ name, project, address } = {}) {
    return client.post(`${BASE_URL}/buildings/`, {
      name,
      project,
      address
    });
  }

  updateBuilding({ name, project, address, id } = {}) {
    return client.put(`${BASE_URL}/buildings/${id}/`, {
      name,
      project,
      address,
    });
  }

  createUnit(bodyRequest) {
    return client.post(`${BASE_URL}/units/`, bodyRequest);
  }

  updateUnit(bodyRequest, id) {
    return client.patch(`${BASE_URL}/units/${id}/`, bodyRequest);
  }

  archiveBuilding(id, state) {
    return client.patch(`${BASE_URL}/buildings/${id}/archive/`, {
      isArchived: state,
    });
  }

  archiveProject(id, state) {
    return client.patch(`${BASE_URL}/projects/${id}/archive/`, {
      isArchived: state,
    });
  }

  archiveUnit(id, state) {
    return client.patch(`${BASE_URL}/units/${id}/archive/`, {
      isArchived: state,
    });
  }

  archiveRoom(id, state) {
    return client.patch(`${BASE_URL}/rooms/${id}/archive/`, {
      isArchived: state,
    });
  }

  exportProject() {
    return client.getRaw(`${BASE_URL}/projects/xlsx/`, {
      responseType: 'blob',
    });
  }

  importProject(project) {
    return client.post(`${BASE_URL}/projects/xlsx/`, project, {
      headers: {
        'Content-Type': project.type,
      },
    });
  }

  async downloadProject(fallbackFilename) {
    const response = await this.exportProject();

    const { filename, blob } = repackResponseFile(response, fallbackFilename);

    download(filename, blob);
  }

  getUnitHistory(id) {
    return client.get(`${BASE_URL}/units/${id}/usages/history/`);
  }

  createRoom({
    externalId,
    header,
    name,
    internalArea,
    goalRentCost,
    status,
    clients,
    parentUnit,
  } = {}) {
    return client.post(`${BASE_URL}/rooms/`, {
      externalId,
      header,
      name,
      internalArea,
      goalRentCost,
      status,
      clients,
      parentUnit,
    });
  }

  updateRoom({
    id,
    externalId,
    header,
    name,
    internalArea,
    goalRentCost,
    status,
    clients,
    parentUnit,
  } = {}) {
    return client.put(`${BASE_URL}/rooms/${id}/`, {
      externalId,
      header,
      name,
      internalArea,
      goalRentCost,
      status,
      clients,
      parentUnit,
    });
  }

  getRoomById(id) {
    return client.get(`${BASE_URL}/rooms/${id}/`);
  }
}

export default new RealtyService();
