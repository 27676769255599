<template>
  <div class="d-flex align-center view-file-item">
    <div class="view-file-item__preview" :class="{ 'view-file-item--bg-blue-60': !isImage }" rounded>
      <button v-if="isImage" type="button" @click="click">
        <v-img :src="url" width="80" height="80" />
      </button>
      <a v-else class="view-file-item__link" :href="url" :download="name">
        <v-icon color="blue20">mdi-text-box</v-icon>
      </a>
    </div>
    <div class="ml-2">
      <p class="view-file-item__text-name">{{ trimFileName(name) }}</p>
      <p class="view-file-item__text-size">{{ convertSizeFile(size) }}</p>
    </div>
  </div>
</template>

<script>
import convertSizeFile from '@/utils/convertSizeFile';
import { middleTruncate } from '@/utils/formatters';

export default {
  name: 'SchemaViewFileItem',

  props: {
    url: { type: String, required: true },
    type: { type: String, required: true },
    size: { type: Number, default: 0 },
    name: { type: String, default: '' },
  },
  computed: {
    isImage() {
      return this.type.startsWith('image/');
    },
  },

  methods: {
    click() {
      this.$emit('click');
    },
    trimFileName(name) {
      return middleTruncate(name);
    },
    convertSizeFile,
  },
};
</script>

<style lang="scss">
.view-file-item {
  width: 230px;
  &__preview {
    width: 80px;
    height: 80px;
    overflow: hidden;
    position: relative;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  &--bg-blue-60 {
    background: $--blue-color-60;
  }

  &__text-name {
    color: $--black-color-0;
    font-weight: $--font-weight-medium;
    font-size: 16px;
    line-height: 28px;
    margin: 0 !important;
    max-width: 180px;
    max-height: 28px;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width: map.get($--screens, 'xs')) {
      max-width: 120px;
    }
  }
  &__text-size {
    color: $--grey-color-0;
    font-weight: $--font-weight-normal;
    font-size: 14px;
    line-height: 20px;
    margin: 0 !important;
  }
  &__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
