<template>
  <div class="view-field">
    <div class="text-body-1 mb-2 text--secondary" :class="`text-${align}`">
      <slot name="label">{{ label }}</slot>
    </div>
    <div class="text-body-1 font-weight-medium" :class="`text-${align}`"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'ViewField',

  props: {
    label: { type: String, default: '' },
    align: { type: String, default: 'left' },
  },
};
</script>

<style lang="scss">
.view-field {
  word-break: break-word;
}
</style>
