<template>
  <view-field class="font-weight-medium" :label="label">
    <router-link :to="link">
      {{ currentValue }}
    </router-link>
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';
import { UNITS_DETAILED } from '@/constants/routes';

export default {
  name: 'SchemaViewFieldUnit',

  components: { ViewField },

  props: {
    value: { type: Object, default: () => ({}) },
    label: { type: String, required: true },
  },

  computed: {
    currentValue() {
      return this.value?.name || '—';
    },

    link() {
      return { name: UNITS_DETAILED, params: { id: this.value?.id } };
    },
  },
};
</script>
