export const LOG_IN = 'Log in';
export const LOG_IN_PAGE = 'Log in page';
export const RESET_PASSWORD_PAGE = 'Reset password page';
export const LOG_OUT = 'Log out';
export const CHANGE_LANGUAGE = 'Change language';
export const PROFILE = 'Profile';
export const INVOICES_LIST = 'Invoices list';
export const INVOICES_DETAILD = 'Incoice details';
export const INVOICES_LIST_SORTING = 'Invoices list sorting';
export const PREPARE_PAYMENT = 'Prepare payment';
export const PAYMENT = 'Payment';
export const CARDS_LIST = 'Cards list';
export const CARD_ADDING = 'Card adding';
export const NEWS_LIST = 'News list';
export const NEWS_SORTING = 'News sorting';
export const NEWS = 'News';
export const CHATS_LIST = 'Chats list';
export const CHAT_VIEWING = 'Chat viewing';
export const SENDING_MESSAGE = 'Sending message';
export const SENDING_MESSAGE_WITH_FILE = 'Sending message with file';
export const INVOICES_LIST_FILTERING = 'Invoices list filtering';
export const CREATE_INVOCE = 'Create invoce';
export const CREATE_RULE = 'Create rule';
export const IMPORT_INVOICES = 'Import invoices';
export const EXPORT_INVOICES = 'Export invoices';
export const EXPORT_ACCOUNTING_DATA = 'Export accounting data';
export const CLIENTS_LIST = 'Clients list';
export const CLIENT = 'Client';
export const CREATE_CLIENT = 'Create client';
export const EDIT_CLIENT = 'Edit client';
export const ARCHIVE_CLIENT = 'Archive client';
export const UNARCHIVE_CLIENT = 'Unarchive client';
export const PROJECTS_LIST = 'Projects list';
export const PROJECT = 'Project';
export const CREATE_PROJECT = 'Create project';
export const EDIT_PROJECT = 'Edit project';
export const ARCHIVE_PROJECT = 'Archive project';
export const UNARCHIVE_PROJECT = 'Unarchive project';
export const BUILDING_VIEWING = 'Building viewing';
export const CREATE_BUILDING = 'Create building';
export const EDIT_BUILDING = 'Edit building';
export const ARCHIVE_BUILDING = 'Archive building';
export const UNARCHIVE_BUILDING = 'Unarchive building';
export const UNIT_VIEWING = 'Unit viewing';
export const CREATE_UNIT = 'Create unit';
export const EDIT_UNIT = 'Edit unit';
export const ARCHIVE_UNIT = 'Archive unit';
export const UNARCHIVE_UNIT = 'Unarchive unit';
export const ROOM_VIEWING = 'Room viewing';
export const CREATE_ROOM = 'Create room';
export const EDIT_ROOM = 'Edit room';
export const ARCHIVE_ROOM = 'Archive room';
export const UNARCHIVE_ROOM = 'Unarchive room';
export const RENTAL_HISTORY_VIEWING = 'Rental history viewing';
export const CREATE_NEWS_DRAFT = 'Create news draft';
export const EDIT_NEWS_DRAFT = 'Edit news draft';
export const DELETE_NEWS_DRAFT = 'Delete news draft';
export const CREATE_SCHEDULED_NEWS = 'Create scheduled news';
export const EDIT_SCHEDULED_NEWS = 'Edit scheduled news';
export const DELETE_SCHEDULED_NEWS = 'Delete scheduled news';
export const EMPLOYEES_LIST = 'Employees list';
export const ROLE = 'Role';
export const ROLES_LIST = 'Roles list';
export const CREATE_ROLE = 'Create role';
export const EDIT_ROLE = 'Edit role';
export const DELETE_ROLE = 'Delete role';
export const CREATE_EMPLOYEES = 'Create employees';
export const EDIT_EMPLOYEES = 'Edit employees';
export const EMPLOYEE = 'Employee';
export const ARCHIVE_EMPLOYEE = 'Archive employee';
export const UNARCHIVE_EMPLOYEE = 'Unarchive employee';
export const DASHBOARD = 'Dashboard';
export const CONTRACTS_LIST = 'Contracts';
export const ARCHIVE_CONTRACTS = 'Archive contract';
export const TASKS_LIST = 'Tasks list';
export const TASK = 'Task';
export const RULE_TASK = 'Rule task';
export const CREATE_TASK = 'Create task';
export const CREATE_RULE_TASK = 'Create rule task';
export const EDIT_TASK = 'Edit task';
export const EDIT_RULE_TASK = 'Edit rule task';
export const CHANGE_TASKS_STATUS = 'Change task status';
export const CREATE_SERVICE = 'Create service';
export const EDIT_SERVICE = 'Edit service';
export const CREATE_SERVICE_CATEGORY = 'Create service category';
export const EDIT_SERVICE_CATEGORY = 'Edit service category';
export const SERVICES_LIST = 'Services list';
export const DETAILED_SERVICE = 'Service';
export const UNARCHIVE_SERVICE = 'Unarchive service';
export const ARCHIVE_SERVICE = 'Archive service';
export const EDIT_CONTRACT = 'Edit contract';
