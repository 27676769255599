<template>
  <v-row no-gutters class="view-field-client-contract" :class="{ 'view-field-client-contract--archived': isArchived }">
    <v-col cols="12" md="3" class="mb-8 mb-md-0">
      <view-field :label="$t('client.project')">
        {{ project.name }}
      </view-field>
    </v-col>

    <v-col cols="12" md="2" class="mb-8 mb-md-0">
      <view-field :label="$t('client.type')">
        {{ clientType }}
      </view-field>
    </v-col>

    <v-col cols="12" md="3">
      <v-row no-gutters>
        <v-col cols="12" md="6" class="mb-8 mb-md-0">
          <view-field :label="$t('client.building')">
            {{ building.name }}
          </view-field>
        </v-col>

        <v-col cols="12" md="6" class="mb-8 mb-md-0">
          <view-field-unit :label="$t('client.unit')" :value="unit" />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="3" class="mb-4 mb-md-0">
      <view-field :label="$t(contractPeriodLabel)">
        <contract-period
          :rental-period-end-date="rentalPeriodEndDate"
          :rental-period-start-date="rentalPeriodStartDate"
          :sales-contract-date="salesContractDate"
        />
      </view-field>
    </v-col>

    <v-col v-if="displayActions" cols="0" md="1" class="d-flex align-center">
      <div class="view-field-client-contract__actions d-flex align-center flex-grow-1 justify-end">
        <v-btn
          :to="contractRoute"
          secondary
          class="view-field-client-contract__action primary--text mr-2"
          elevation="0"
          color="secondary"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          secondary
          :loading="isLoading"
          class="view-field-client-contract__action primary--text"
          elevation="0"
          color="secondary"
          @click="archive"
        >
          <v-icon>mdi-archive</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ViewField from '@/components/ViewField.vue';
import ViewFieldUnit from '@/components/schema/ViewFieldUnit.vue';
import usersService from '@/services/users';
import { CONTRACTS_DETAILED, CLIENTS_DETAILED } from '@/constants/routes';
import viewBuilderService from '@/services/viewBuilder';
import ContractPeriod from '@/components/ContractPeriod.vue';
import { OWNER } from '@/constants/typesClients';
import * as subjects from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

export default {
  name: 'ViewFieldClientContract',

  components: { ViewField, ViewFieldUnit, ContractPeriod },

  props: {
    usageId: { type: Number, required: true },
    project: { type: Object, required: true },
    clientType: { type: String, required: true },
    building: { type: Object, required: true },
    unit: { type: Object, required: true },
    rentalPeriodEndDate: { type: String, default: '' },
    rentalPeriodStartDate: { type: String, default: '' },
    salesContractDate: { type: String, default: '' },
    isArchived: { type: Boolean, default: false },
    unitId: { type: Number, required: true },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    contractRoute() {
      return {
        name: CONTRACTS_DETAILED,
        params: { id: this.usageId },
        query: { pageName: CLIENTS_DETAILED, id: this.unitId },
      };
    },

    displayActions() {
      return !this.isArchived && this.$can(UPDATE, subjects.CONTRACTS);
    },

    contractPeriodLabel() {
      return this.clientType === OWNER ? 'client.sales_contract_date' : 'client.rent';
    },
  },

  methods: {
    archive() {
      this.isLoading = true;

      usersService
        .archiveUsages({
          usages: [this.usageId],
          isArchived: true,
        })
        .then(() => {
          viewBuilderService.notify('archive-usage');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.view-field-client-contract {
  $action-button-width: 36px;
  position: relative;
  border-bottom: 1px solid rgba($--blue-color-20, 0.1);

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    border-bottom: none;
  }

  &--archived {
    opacity: 0.6;
  }

  &__action {
    width: $action-button-width !important;
    height: $action-button-width !important;
    min-width: $action-button-width !important;
    min-height: $action-button-width !important;
    padding: 6px !important;

    &:not(:hover):not(:focus)::before {
      opacity: 0.08 !important;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      position: initial;
    }
  }
}
</style>
