<template>
  <div v-if="isTabs">
    <div class="tabs">
      <v-tabs v-model="tab" color="#1E1E22">
        <v-tabs-slider color="#1E1E22"></v-tabs-slider>

        <v-tab v-for="tabItem in tabs" :key="tabItem.value">
          {{ $t(tabItem.label) }}
        </v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tabItem in tabs" :key="tabItem.value" :transition="false">
        <ViewItem
          v-for="item in filteredSchema"
          v-show="isShow(item)"
          :key="item.prop"
          class="mb-8"
          v-bind="item"
          :data="data[item.prop]"
          :prop-path="getPropertyPath(item)"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
  <div v-else>
    <ViewItem
      v-for="item in filteredSchema"
      v-show="isShow(item)"
      :key="item.prop"
      class="mb-8"
      v-bind="item"
      :data="data[item.prop]"
      :prop-path="getPropertyPath(item)"
    />
  </div>
</template>

<script>
import * as schemaItemTypes from '@/schemas/schemaItemTypes';

import { getPropertyByPath } from '@/utils/schema';
import { rules } from '@/schemas/validationRules';

import * as builderTypes from '@/constants/builder';
import * as valueTypes from '@/constants/value';

import ViewItem from './ViewItem.vue';

export default {
  name: 'SchemaViewBuilder',

  components: {
    ViewItem,
  },

  provide() {
    return {
      getPropertyValue: this.getPropertyValue,
      mustDisplaySchemaItem: this.mustDisplaySchemaItem,
    };
  },

  props: {
    schema: {
      type: Array,
      default: () => [],
    },
    schemaList: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({}),
    },

    isTabs: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filteredSchema() {
      return this.isTabs ? this.filterSchema(this.schemaList[this.tab]) : this.filterSchema(this.schema);
    },
    tab: {
      get() {
        return this.activeTab;
      },
      set(newTab) {
        this.$emit('change-tab', newTab);
      },
    },
  },

  methods: {
    filterSchema(schema) {
      return schema.filter(item => this.mustDisplaySchemaItem(item, [item.prop]));
    },
    mustDisplaySchemaItem(item, propPath) {
      const hasVisibilityNone = item?.visibility?.find(
        visibleItem => visibleItem.builderType === builderTypes.VIEW && visibleItem.value === valueTypes.NONE
      );

      if (hasVisibilityNone) {
        return false;
      }

      const hasRequiredRule = item?.rules?.includes(rules.REQUIRED);

      if (hasRequiredRule) {
        return true;
      }

      const value = this.getPropertyValue({ propPath });
      return !!value || this.checkDisplayWithoutProp(item);
    },

    getPropertyValue({ propPath = [] }) {
      return getPropertyByPath(this.data, propPath);
    },

    getPropertyPath(item) {
      return item.prop ? [item.prop] : [];
    },

    isShow(item) {
      return !item?.visibility?.find(
        visibleItem => visibleItem.builderType === builderTypes.VIEW && visibleItem.value === valueTypes.HIDDEN
      );
    },

    checkDisplayWithoutProp(item) {
      return [
        schemaItemTypes.TITLED_SECTION,
        schemaItemTypes.ROW,
        schemaItemTypes.FLEX_ROW,
        schemaItemTypes.TITLE,
        schemaItemTypes.TITLED_SECTION_WITH_STATUS,
        schemaItemTypes.EXPANSION_SECTION,
        schemaItemTypes.CARD_SECTION,
        schemaItemTypes.CONTRACTS,
      ].includes(item.type);
    },
  },
};
</script>

<style lang="scss">
.tabs {
  .v-window {
    padding: 15px;
    margin: -15px;
  }
  .v-tabs {
    margin-bottom: 41px;

    .v-slide-group__prev,
    .v-slide-group__next {
      display: none !important;
    }
  }

  .v-tab {
    padding: 0;
    min-width: 0;

    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: none;

    &::before,
    &::after {
      display: none !important;
    }
  }

  .v-tab + .v-tab {
    margin-left: 24px;
  }

  .v-tabs-bar {
    height: 36px;
  }
}
</style>
