// формирующие модель данных
export const STRING = 'string';
export const NUMBER = 'number';
export const BOOLEAN = 'boolean';
export const PROJECT = 'project';
export const BUILDING = 'building';
export const EMPLOYEES = 'employees';
export const PERFORMERS = 'performers';
export const ENUM = 'enum';
export const IMAGE_LIST = 'imageList';
export const FILE_LIST = 'fileList';

export const LINK = 'link';
export const DATE = 'date';
export const DATE_WITH_PANEL = 'dateWithPanel';
export const CLIENT = 'client';
export const CONTRACTOR = 'contractor';
export const PERCENT = 'percent';
export const UNIT = 'unit';
export const TOTAL_AMOUNT = 'totalAmount';
export const SQUARE = 'square';
export const COUNTRY = 'country';
export const CATEGORY = 'category';
export const MANY_PROJECTS = 'manyProjects';
export const MANY_UNITS = 'manyUnits';
export const MANY_BUILDINGS = 'manyBuildings';
export const MANY_CLIENTS = 'manyClients';
export const MANY_PERFORMERS = 'manyPerformers';
export const MANY_PERFORMERS_FOR_TASK = 'manyPerformersForTask';
export const MANY_PERFORMERS_FOR_PROJECT = 'manyPerformersForProject';
export const MANY_SERVICE_TYPES = 'manyServiceTypes';
export const MANY_CONTRACTORS_TYPES = 'manyContractorsTypes';
export const MANY_PAYMENT_TYPE = 'manyPaymentType';
export const TIME = 'time';
export const CREDENTIALS_VARIANT = 'credentialsVariant';
export const COAST = 'coast';
export const SEARCH_BAR = 'searchBar';
export const SCRIPT_ITEM = 'scriptItem';
export const CONTRACTS = 'contracts';
export const UNIT_CONTRACTS = 'unitContracts';
export const UNIT_ENUM = 'unitEnum';
export const EMPLOYEES_ROLES = 'employeesRoles';
export const ROLES_LIST = 'rolesList';
export const TEAM_LIST = 'teamList';
export const ROLE = 'role';

export const LIST = 'list';
export const SCRIPT_LIST = 'scriptList';

// не влияют на модель данных
export const TITLE = 'title';
export const TITLED_SECTION = 'titledSection';
export const TITLED_SECTION_WITH_STATUS = 'titledSectionWithStatus';
export const UNIT_SALES_HISTORY = 'unitSalesHistory';
export const ROW = 'row';
export const FLEX_ROW = 'flex-row';
export const EXPANSION_SECTION = 'expansionSection';
export const CARD_SECTION = 'cardSection';
export const TASK_STATUS_CHANGE = 'taskStatusChange';
export const EXPANSION_PANEL = 'expansionPanel';
export const TYPES_WITHOUT_EFFECT = Object.freeze([
  ROW,
  FLEX_ROW,
  TITLED_SECTION,
  CARD_SECTION,
  TASK_STATUS_CHANGE,
  LIST,
  EXPANSION_PANEL,
]);
