<template>
  <div>
    <!-- @todo add edit route -->
    <clients-entity-header
      class="mb-10"
      :title="clientFullName"
      :status="client.status"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdateClients"
      :is-short-tenant="isShortTenant"
      :is-loading="isLoading"
      @archive="archive"
      @resent-email="resentEmail"
    />
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn color="primary" block @click="closeModal">{{ $t('clients.back_to_client') }}</v-btn>
    </info-modal>

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder v-else :schema="schema" :data="client" />
  </div>
</template>

<script>
// Models
import { schema, schemaForShortTenants } from '@/schemas/client.view.schema';

// Services
import usersService from '@/services/users';
import analyticsService from '@/services/analytics';
import ViewBuilderService from '@/services/viewBuilder';

// Constants
import { CLIENTS_EDIT, CLIENTS_ARCHIVE, CLIENTS } from '@/constants/routes';
import * as entityStatuses from '@/constants/entityStatuses';

// Components
import ViewBuilder from '@/components/schema/ViewBuilder.vue';
import ClientsEntityHeader from '@/components/Clients/EntityHeader.vue';
import { CLIENT, ARCHIVE_CLIENT, UNARCHIVE_CLIENT } from '@/constants/analyticsActions';
import { CLIENTS as CLIENTS_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';
import InfoModal from '@/components/InfoModal.vue';

export default {
  unsubscribe: null,

  name: 'Client',

  components: { ClientsEntityHeader, ViewBuilder, InfoModal },

  props: {
    id: { type: Number, required: true },
    prevPage: { type: Number, default: 0 },
  },

  data() {
    return {
      isLoading: false,
      client: {},
      modal: {
        show: false,
        message: '',
      },
    };
  },

  computed: {
    canUpdateClients() {
      return this.$can(UPDATE, CLIENTS_SUBJECT);
    },

    backRoute() {
      const routeName = this.client.isArchived ? CLIENTS_ARCHIVE : CLIENTS;

      return { name: routeName, query: { page: this.prevPage || 1 } };
    },

    clientFullName() {
      if (this.isLoading) return '';
      return `${this.client.firstName} ${this.client.lastName}`;
    },

    editRoute() {
      return { name: CLIENTS_EDIT, params: { id: this.id } };
    },

    schema() {
      if (this.isShortTenant) {
        return schemaForShortTenants;
      }
      return schema;
    },
    isShortTenant() {
      return this.client.isShortTermTenant;
    },
    isArchived() {
      return this.client.status === entityStatuses.ARCHIVED;
    },
  },

  mounted() {
    analyticsService.track(CLIENT);
    this.getClient();

    this.$options.unsubscribe = ViewBuilderService.subscribe('archive-usage', () => this.getClient());
  },

  beforeDestroy() {
    this.$options.unsubscribe();
  },

  methods: {
    getClient() {
      this.isLoading = true;
      usersService
        .getClientById(this.id)
        .then(client => {
          this.client = client;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    archive() {
      analyticsService.track(this.isArchived ? UNARCHIVE_CLIENT : ARCHIVE_CLIENT);
      this.isLoading = true;
      usersService.archiveClientById(this.id, !this.isArchived).then(() => {
        this.getClient();
      });
    },
    resentEmail() {
      const options = { clients: [this.id] };
      this.isLoading = true;
      usersService
        .passwordResetMail(options)
        .then(() => {
          this.showModal(this.$t('client.resent_success'));
        })
        .catch(() => {
          this.showModal(this.$t('client.resent_fail'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showModal(message) {
      this.modal.message = message;
      this.modal.show = true;
    },
    closeModal() {
      this.modal.message = '';
      this.modal.show = false;
    },
  },
};
</script>
