<template>
  <h2 class="text-h5 font-weight-bold mt-16 mb-8">
    <slot>{{ label }}</slot>
  </h2>
</template>

<script>
export default {
  name: 'SchemaViewTitle',

  props: {
    label: { type: String, default: '' },
  },
};
</script>
