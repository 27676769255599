// eslint-disable-next-line import/prefer-default-export
export const monthsTanslations = [
  'dates_short_months.january',
  'dates_short_months.february',
  'dates_short_months.march',
  'dates_short_months.april',
  'dates_short_months.may',
  'dates_short_months.june',
  'dates_short_months.jule',
  'dates_short_months.august',
  'dates_short_months.september',
  'dates_short_months.october',
  'dates_short_months.november',
  'dates_short_months.december',
];
